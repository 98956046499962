import './init.js';

import './bootstrap';
import './../css/plyr.css';
import './../../node_modules/pikaday/css/pikaday.css';
import "./../css/pika-white.css";




import Pikaday from 'pikaday';

import SimpleLightBox from "simplelightbox";

window.simpleLightBox = new SimpleLightbox('a.resize-image', {fileExt: 'png|jpg|jpeg|gif|webp|avif',download: 'Скачать оригинал'});

window.Pikaday = Pikaday;







